import "./App.css";
import twitterIntent from "twitter-intent";

//https://twitter.com/intent/tweet?text=Hey%20@elonmusk%2C%20at%20least%20there%20will%20always%20be%20money%20in%20the%20emerald%20mine
function Header() {
  return (
    <h1 className="Headline">
      <span>there's always </span>
      <br />
      <span>money in the</span>
      <br />
      <span>emerald mine</span>
    </h1>
  );
}

function Button({tweet, label}) {
  const href = twitterIntent.tweet.url({
    text: tweet ,
  });
  return (
    <div class="flex justify-center items-center">
    <button class="bg-emerald-500 hover:bg-emerald-400 text-white font-bold py-2 px-4 border-b-4 border-emerald-700 hover:border-emerald-500 rounded">
      <a href={href}> {label}  </a>
    </button>
    </div>
  );
}

function Body() {
  let tweet = "don't worry about wrecking Twitter @elonmusk, there's always money in the emerald mine https://theresalwaysmoneyintheemeraldmine.com";
  return (
    <h1 className="Body">
      <p>
        Elon Musk is a billionaire and often considered the richest man in the
        world. After flirting with the idea for years, Elon finally bought
        Twitter outright, taking the company private and installing himself as
        the Chief Executive Officer of the company and promising swift changes.
      </p>
      <br />
      <p>
      Under Musk's leadership, <b class="bg-emerald-300 text-black ">Twitter has been run straight into the fucking ground.</b> Twitter has <a href="https://www.barrons.com/articles/elon-musk-twitter-advertisers-51667586868"> reportedly had a "massive" drop in revenue</a> as major advertisers flee from the platform. Twitter Blue, one of Musk's first major projects at Twitter, was <a href="https://gizmodo.com/twitter-eli-lilly-elon-musk-insulin-1849779323">such a debacle that it cut $15 billion dollars off the market cap of a company</a> thanks to the swath of impersonators that swarmed the platform after Twitter Blue was launched and <a href="https://nypost.com/2022/11/11/twitter-blue-launch-suspended-as-verified-account-impersonators-surge-report/">then hastily suspended</a>. 
      </p>
      <br />
      <p>
      Currently, <b class="bg-emerald-300 text-black "><a href="https://twitter.com/ZoeSchiffer/status/1593391954301054976">Musk is reportedly barricading himself and his team inside Twitter HQ</a></b> because they no longer trust Twitter employees not to sabotage the website entirely. This all comes after <a href="https://www.nytimes.com/2022/11/16/technology/elon-musk-twitter-employee-deadline.html">Musk laid off half of the company</a>. After a huge number of resignations, there is <a href="https://twitter.com/wyntermitchell/status/1593382511098269696">speculation that there is only 238 employees left at the company in total</a>, down from 9,000 just a few weeks ago. 
      </p>
      <br />
      <p>Elon Musk is personally destroying something that <a href="https://twitter.com/zackmaril">I love very much</a>. Twitter has been a part of my life for over a decade now and it's shaped who I am and how I view the world. I've learnt and seen things I cannot imagine I otherwise would have without Twitter and made life long friends online there who are important to me.  It breaks my heart to see it die like this, without any respect or dignity.
      </p>
      <br />
      <p><b class="bg-emerald-300 text-black ">So I am asking you to help me break Elon Musk's brain.</b></p>
      <br />
      <p>
      We know he reads his mentions, we know he name searches constantly, we know that he is one of the most online people who has ever lived. Through all of his posting, we also know that <b class="bg-emerald-300 text-black "><a href="https://www.snopes.com/news/2022/11/17/elon-musk-emerald-mine/">he hates when people point out the fact that his father owned a share of an emerald mine</a></b>. People have made fun of him online about it for years now and he seemingly only gets more mad and frustrated about it every time it comes up.</p>
      <br />

      <p>
      When you <b class="bg-emerald-300 text-black ">press the button below</b> it will create a Tweet for you to post tagging Musk and directing your followers back to this website. In the past, I've found this to be the most effective way of filling somebody's mentions with people asking them about things they would rather avoid thinking about. <a href="https://twitter.com/search?q=https%3A%2F%2Ftheresalwaysmoneyintheemeraldmine.com%2F&src=typed_query&f=live">Together, if we all hit this button hard enough, I think we can make him go insane</a>.
      </p>
      <br />
      <p><b class="bg-emerald-300 text-black ">And that's beautiful.</b></p>
      <br />
      <Button label="Tweet at Elon now" tweet={tweet}/>
      <br />
      <p>
      So, please, hit that button and help me remind Elon that no matter what happens with Twitter, <b class="bg-emerald-300 text-black ">there's always money in the emerald mine</b>. 
      </p>
      <br />
      <div class="flex justify-center items-center">
      <img src="/chaos_emerald.png" />
      </div>
    </h1>
  );
}

function App() {
  return (
    <body class="bg-emerald-900">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-xl text-emerald-100">
          <Header />
          <Body />
        </div>
      </div>
    </body>
  );
}

export default App;
